// i18next-extract-mark-ns-start stripe-alternative

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {List} from 'components/List';
import {MobilePlanLabel, PlansContainer, PricingSection} from 'components/pricing/PricingSection';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import selling from 'images/selling.svg';
import logo from 'images/stripe.svg';
import React from 'react';
import {HiCheck, HiOutlineX} from 'react-icons/all';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {BlogLink} from 'components/links/Blog';
import {PageContext} from 'gatsby/internal';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {StyledLi} from '../components/StyledSx';
import {ScreenSizes} from '../../types/responsive';

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 515px;
`;

const PlanHeader = styled.div`
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 0 50%;

  @media (max-width: 1280px) {
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const Plan = styled.div`
  margin: -30px 10px 50px 10px;
  position: relative;
  z-index: 1;
  width: 250px;
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: 1280px) {
    width: 100%;
    margin: 50px 0 0;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 20px 0 0;
  }
`;

const ListRow = styled(StyledLi)`
  padding: 10px 40px;
  align-items: center;
  min-height: 66px;
  display: flex; // Use flexbox for layout
  justify-content: space-between; // Distribute space between items
  span {
    flex: 1; // Allow the text to take up as much space as it needs
    overflow: hidden; // Hide any overflow
    text-overflow: ellipsis; // Add an ellipsis (...) if the text is too long
    white-space: nowrap; // Prevent the text from wrapping to the next line
  }
  :nth-child(even) {
    background: #f5f5f5;
  }
  img {
    margin: auto 0;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 10px 24px;
    display: flex;
    img {
      margin: 0 8px 0 0;
    }
  }
`;

const PricingContent = styled(Content)`
  padding-bottom: 100px;
`;

const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 150px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 100%;

  li:after {
    display: block;
    content: '';
    width: 75%;
  }

  @media (max-width: 1280px) {
    display: none;
  }
`;

const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  ${ListRow} {
    justify-content: center;
  }
`;

export const PlansList = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1280px) {
    justify-content: initial;
    overflow-x: scroll;
  }
`;

const YesIcon = styled(HiCheck)`
  font-size: 32px;
  color: green;
`;

const NoIcon = styled(HiOutlineX)`
  font-size: 32px;
  color: red;
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const StripeAlternative: React.FC<PageContext> = (props) => {
  const {t} = useI18next();
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        path="stripe-alternative"
        title={t('Stripe Alternative')}
        description={t(
          'Looking for the best alternative to Stripe? Accept all major payment methods in a single platform with MONEI’s payment gateway. Join now ››'
        )}
      />
      <IndexBackground sx={{left: {xl: '50%'}, height: {xl: '1000px'}, width: {xl: '2000px'}}}>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Stripe Alternative</Trans>
            </SectionHeader>
            <Trans parent="p">
              While Stripe has gained popularity in the last few years, MONEI’s payment gateway is a
              leading alternative to Stripe. Now you can accept online payments without astronomical
              transaction fees in even more countries, including{' '}
              <Link to="/andorra-payment-gateway/">Andorra</Link>.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <IndexImage
              top="60px"
              width="700px"
              src={selling}
              alt="PayPal Alternative"
              title="PayPal Alternative"
              className="hide-on-mobile"
            />
          </IndexContent>
        </Content>
      </IndexBackground>
      <Background>
        <PricingContent>
          <Section centered>
            <div style={{maxWidth: 800}}>
              <SectionHeader>
                <Trans>Are you having trouble integrating Stripe and Bizum?</Trans>
              </SectionHeader>
              <Trans parent="p">
                If you’re looking for an easy, secure, and frictionless alternative to the long and
                tedious Stripe Bizum integration process, you’ve come to the right place. After{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/360017801677">
                  creating
                </AnchorLink>{' '}
                your MONEI account,{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/360018047698">
                  contact us to active Bizum
                </AnchorLink>
                . Once it’s configured, the Bizum button will{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4463510317713">
                  appear on your payment page
                </AnchorLink>{' '}
                so customers can choose this option at checkout.
              </Trans>
              <Trans parent="h2">Why Choose MONEI?</Trans>
              <Trans parent="p">
                Focused on innovation, reliability, and user satisfaction, our team presented
                MONEI’s architecture at{' '}
                <AnchorLink
                  href="https://aws.amazon.com/es/blogs/startups/serverless-architecture-powers-moneis-fast-secure-and-scalable-digital-payment-solutions/"
                  target="_blank"
                  title="Winner of the AWS’ Startup Architecture Challenge">
                  Amazon’s AWS Startup Architecture of the Year Program (Iberia region)
                </AnchorLink>
                — and we won!
              </Trans>
              <Trans parent="p">
                There are several advantages — for all types of online merchants — to choosing MONEI
                over Stripe as your <Link to="/">payment gateway</Link> alternative.
              </Trans>
              <List>
                <Trans parent="li">
                  Become a verified merchant, and benefit from a 1-day payment settlement system
                  (get your money in 24 hours).
                </Trans>
                <Trans parent="li">
                  Get lower transaction fees, so you have more money to reinvest and grow your
                  business.
                </Trans>
                <Trans parent="li">
                  Protect yourself and your customers with{' '}
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3D Secure Payments
                  </BlogLink>
                  .
                </Trans>
                <Trans parent="li">
                  Use our API to quickly and easily integrate MONEI with your e-commerce platform —
                  you’ll be ready to sell in minutes.
                </Trans>
                <Trans parent="li">
                  Make MONEI yours. Customize and brand your store’s checkout experience with our
                  white label solution: customers will only see your branding and domain during the
                  checkout process, not ours.
                </Trans>
                <Trans parent="li">
                  MONEI grows with you. Take advantage of an{' '}
                  <Link to="/pricing/">evolutive pricing system</Link>: the more you sell, the lower
                  your variable fee will be. This tapering rate, as well as lower fixed fees, means
                  you’ll experience the lowest payment gateway costs on the market.
                </Trans>
              </List>
              <div style={{textAlign: 'center', marginBottom: 50}}>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </div>
            </div>
          </Section>
          {/* i18next-extract-mark-ns-stop stripe-alternative */}
          {/* i18next-extract-mark-ns-start alternatives */}
          <PricingSection>
            <PlansTable>
              <PlanLabels>
                <Trans parent={ListRow}>3D Secure transactions</Trans>
                <Trans parent={ListRow}>1-day payment settlement</Trans>
                <Trans parent={ListRow}>User-friendly APIs</Trans>
                <Trans parent={ListRow}>Mobile payments</Trans>
                <Trans parent={ListRow}>Hosted service</Trans>
                <Trans parent={ListRow}>Google Pay</Trans>
                <Trans parent={ListRow}>Apple Pay</Trans>
                <Trans parent={ListRow}>PayPal</Trans>
                <Trans parent={ListRow}>Recurring payments</Trans>
                <Trans parent={ListRow}>PCI DSS Level 1</Trans>
                <Trans parent={ListRow}>Free and multilingual onboarding and support</Trans>
                <Trans parent={ListRow}>
                  Integration with popular e-commerce platforms (Shopify, Wix, WooCommerce...)
                </Trans>
                <Trans parent={ListRow}>Multilingual dashboard</Trans>{' '}
                <Trans parent={ListRow}>Real-time control panel with advanced analytics</Trans>
                <Trans parent={ListRow}>
                  Client database with location and device info for each payment
                </Trans>
                <Trans parent={ListRow}>Advanced payments filters (payments and clients)</Trans>
                <Trans parent={ListRow}>
                  Multi-user capabilities with different access levels (admin or read-only)
                </Trans>
                <Trans parent={ListRow}>
                  All historical transaction data available (not only 12 months)
                </Trans>
                <Trans parent={ListRow}>Pay By Link (send a payment link via email or SMS)</Trans>
                <Trans parent={ListRow}>Process refunds from the control panel</Trans>
                <Trans parent={ListRow}>
                  Customize the appearance of the payment screen (logo, brand colors...)
                </Trans>
                <Trans parent={ListRow}>
                  Payment frame in your custom domain (the user always sees mysite.com)
                </Trans>
                <Trans parent={ListRow}>
                  On-site payments (no redirects), including Dynamic 3DS to improve conversion rates
                </Trans>
                <Trans parent={ListRow}>Real-time credit card info formatting</Trans>
                <Trans parent={ListRow}>
                  Automatic translation of placeholders to the customer's default language
                </Trans>
                <Trans parent={ListRow}>
                  Responsive design to adapt modules to the customer's screen
                </Trans>
                <Trans parent={ListRow}>
                  Customizable payment screen to offer a 100% integrated experience
                </Trans>
                <Trans parent={ListRow}>REST API connection for all kinds of integrations</Trans>
                <Trans parent={ListRow}>Payment methods tokenization</Trans>
                <Trans parent={ListRow}>
                  Webhooks to visualize all your clients/payments info from multiple sources (ERP,
                  CRM...)
                </Trans>
                <Trans parent={ListRow}>GraphQL API for data consumption</Trans>
                <Trans parent={ListRow}>
                  SMS and email notifications to clients for receipts, orders, and invoices
                </Trans>
                <Trans parent={ListRow}>
                  Smart routing engine sends payments sent to multiple acquirers to approve more
                  payments
                </Trans>
                <Trans parent={ListRow}>Full Direct Debit SEPA integration</Trans>
                <Trans parent={ListRow}>
                  Full white label solution for financial institutions and banks
                </Trans>
                <Trans parent={ListRow}>Combined acquiring and payment gateway solution</Trans>
                <Trans parent={ListRow}>
                  Transaction processing with new local payment methods like Bizum
                </Trans>
                <Trans parent={ListRow}>Installment payments</Trans>
                <Trans parent={ListRow}>
                  Dynamic billing — as you sell more, your transaction fees decrease
                </Trans>
                <Trans parent={ListRow}>Ready to use React components</Trans>
              </PlanLabels>
              <PlansContainer>
                <PlansList>
                  <Plan>
                    <PlanHeader>
                      <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" width={180} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>User-friendly APIs</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Free and multilingual onboarding and support
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Multilingual dashboard</Trans>
                        <YesIcon />
                      </ListRow>{' '}
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time control panel with advanced analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Client database with location and device info for each payment
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Advanced payments filters (payments and clients)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          All historical transaction data available (not only 12 months)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Process refunds from the control panel
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Payment frame in your custom domain (the user always sees mysite.com)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          On-site payments (no redirects), including Dynamic 3DS to improve
                          conversion rates
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time credit card info formatting
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Automatic translation of placeholders to the customer's default language
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Responsive design to adapt modules to the customer's screen
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Webhooks to visualize all your clients/payments info from multiple sources
                          (ERP, CRM...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Ready to use React components</Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                  <Plan>
                    <PlanHeader>
                      <img src={logo} alt="Stripe" width={150} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>User-friendly APIs</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Free and multilingual onboarding and support
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Multilingual dashboard</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time control panel with advanced analytics
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Client database with location and device info for each payment
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Advanced payments filters (payments and clients)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          All historical transaction data available (not only 12 months)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Process refunds from the control panel
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Payment frame in your custom domain (the user always sees mysite.com)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          On-site payments (no redirects), including Dynamic 3DS to improve
                          conversion rates
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Real-time credit card info formatting
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Automatic translation of placeholders to the customer's default language
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Responsive design to adapt modules to the customer's screen
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Webhooks to visualize all your clients/payments info from multiple sources
                          (ERP, CRM...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Ready to use React components</Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                </PlansList>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </PricingContent>
      </Background>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
    </>
  );
};

export default StripeAlternative;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {in: ["common", "stripe-alternative", "alternatives"]}
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
